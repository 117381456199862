<template>
    <div id="lottery_mine">
        <div style="padding-bottom: 100px;">
            <van-tabs v-model="common.lotteryIndex">
                <van-tab title="全部">
                    <div class="container" v-for="(item,index) in list.history" :key="index"
                         @click="$router.push({ path: '/lottery/detail', query: { act_id: item.act_id }})">
                        <div style="box-shadow: 0 8px 12px #ebedf0;border-radius:10px;background: #fff;">
                            <div style="font-size: 14px;font-weight: bold;padding: 10px 10px 10px 16px;border-bottom: 1px solid #ebedf0;display: flex;">
                                <span style="flex: 1 1 auto;">{{item.goods_name}}</span>
                                <div style="flex: 0 0 auto;">
                                    <van-tag plain v-if="item.text.status === '已中奖'" :type="item.text.type">已中奖待领取</van-tag>
                                    <van-tag plain v-else-if="item.text.status === '已领取'" :type="item.text.type">已领取待使用</van-tag>
                                    <van-tag plain v-else :type="item.text.type">{{item.text.status}}</van-tag>
                                </div>
                            </div>
                            <van-cell-group class="list-cell" style="overflow:hidden;border-radius: 0 0 10px 10px;">
                                <van-cell title="店铺" :value="item.shop_name"/>
                                <van-cell title="地址" :value="item.short_name"/>
                                <van-cell title="取消时间" v-if="item.canceled_at" :value="item.canceled_at"/>
                                <van-cell title="使用时间" v-else-if="item.consumed_at" :value="item.consumed_at"/>
                                <van-cell title="领取时间" v-else-if="item.draw_time" :value="item.draw_time"/>
                                <van-cell title="中奖时间" v-else-if="item.win_at" :value="item.win_at"/>
                                <van-cell title="开奖时间" v-else-if="item.end_time" :value="item.end_time"/>
                            </van-cell-group>
                            <p style="margin: 10px 0;display: flex;align-items: center;" v-if="item.text.permit && item.text.permit === 'receive'">
                                <span style="flex: 1 1 auto;padding-left: 16px;font-size: 14px;"><van-count-down v-if="item.time" style="display: inline;color: red;font-weight: bold;" :time="item.time"/>&nbsp;内领取</span>
                                <van-button size="small" round style="padding: 0 15px;margin-right: 10px;flex: 0 0 auto;
                            background: linear-gradient(to right, rgb(255, 96, 52), rgb(238, 10, 36));color: #fff;"
                                            @click.stop="$router.push({path: '/lottery/confirm', query: {act_id: item.act_id}})"
                                >立即领取</van-button>
                            </p>
                            <p style="text-align: right;margin: 10px 0;" v-if="item.text.permit && item.text.permit === 'use'">
                                <van-button size="small" round style="padding: 0 15px;margin-right: 10px;
                            background: linear-gradient(to right, rgb(255, 96, 52), rgb(238, 10, 36));color: #fff;"
                                            @click.stop="$router.push({path: '/lottery/consume', query: {act_id: item.act_id}})"
                                >查看中奖凭证</van-button>
                            </p>
                        </div>
                    </div>
                    <van-empty description="暂无数据" v-if="!list.history.length"/>
                </van-tab>


                <van-tab title="已中奖">
                    <div class="container" v-for="(item,index) in list.pending" :key="index" @click="$router.push({ path: '/lottery/detail', query: { act_id: item.act_id }})">
                        <div style="box-shadow: 0 8px 12px #ebedf0;border-radius:10px;background: #fff;">
                            <div style="font-size: 14px;font-weight: bold;padding: 10px 10px 10px 16px;border-bottom: 1px solid #ebedf0;display: flex;">
                                <span style="flex: 1 1 auto;">{{item.goods_name}}</span>
                            </div>
                            <van-cell-group class="list-cell" style="overflow:hidden;border-radius: 0 0 10px 10px;">
                                <van-cell title="店铺" :value="item.shop_name"/>
                                <van-cell title="地址" :value="item.short_name"/>
                                <van-cell title="中奖时间" :value="item.win_at"/>
                            </van-cell-group>
                            <p style="margin: 10px 0;display: flex;align-items: center;">
                                <span style="flex: 1 1 auto;padding-left: 16px;font-size: 14px;"><van-count-down v-if="item.time" style="display: inline;color: red;font-weight: bold;" :time="item.time" @finish="loadData" />&nbsp;内领取</span>
                                <van-button size="small" round style="padding: 0 15px;margin-right: 10px;flex: 0 0 auto;
                            background: linear-gradient(to right, rgb(255, 96, 52), rgb(238, 10, 36));color: #fff;"
                                            @click.stop="$router.push({path: '/lottery/confirm', query: {act_id: item.act_id}})"
                                >立即领取</van-button>
                            </p>
                        </div>
                    </div>
                    <van-empty description="暂无数据" v-if="!list.pending.length"/>
                </van-tab>


                <van-tab title="已领取">
                    <div class="container" v-for="(item,index) in list.win" :key="index" @click="$router.push({ path: '/lottery/detail', query: { act_id: item.act_id }})">
                        <div style="box-shadow: 0 8px 12px #ebedf0;border-radius:10px;background: #fff;">
                            <div style="font-size: 14px;font-weight: bold;padding: 10px 10px 10px 16px;border-bottom: 1px solid #ebedf0;display: flex;">
                                <span style="flex: 1 1 auto;">{{item.goods_name}}</span>
                            </div>
                            <van-cell-group class="list-cell" style="overflow:hidden;border-radius: 0 0 10px 10px;">
                                <van-cell title="店铺" :value="item.shop_name"/>
                                <van-cell title="地址" :value="item.short_name"/>
                                <van-cell title="领取时间" :value="item.draw_time"/>
                            </van-cell-group>
                            <p style="text-align: right;margin: 10px 0;">
                                <van-button size="small" round style="padding: 0 15px;margin-right: 10px;
                            background: linear-gradient(to right, rgb(255, 96, 52), rgb(238, 10, 36));color: #fff;"
                                            @click.stop="use(item)">查看中奖凭证</van-button>
                            </p>
                        </div>
                    </div>
                    <van-empty description="暂无数据" v-if="!list.win.length"/>
                </van-tab>


                <van-tab title="已使用">
                    <div class="container" v-for="(item,index) in list.used" :key="index" @click="$router.push({ path: '/lottery/detail', query: { act_id: item.act_id }})">
                        <div style="box-shadow: 0 8px 12px #ebedf0;border-radius:10px;background: #fff;">
                            <div style="font-size: 14px;font-weight: bold;padding: 10px 10px 10px 16px;border-bottom: 1px solid #ebedf0;display: flex;">
                                <span style="flex: 1 1 auto;">{{item.goods_name}}</span>
                            </div>
                            <van-cell-group class="list-cell" style="overflow:hidden;border-radius: 0 0 10px 10px;">
                                <van-cell title="店铺" :value="item.shop_name"/>
                                <van-cell title="地址" :value="item.short_name"/>
                                <van-cell title="使用时间" :value="item.consumed_at"/>
                            </van-cell-group>
                        </div>
                    </div>
                    <van-empty description="暂无数据" v-if="!list.used.length"/>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>

<script>
    import {Dialog} from "vant";
    import {mapState} from 'vuex';

    export default {
        name: "LotteryMine",
        computed:{
            ...mapState(['common']),
        },
        data() {
            return {
                list: {
                    history: [],
                    pending: [],
                    win: [],
                    used: [],
                },
            }
        },
        mounted() {
            if(this.$route.query.active !== undefined){
                console.log(this.$route.query.active);
                this.common.lotteryIndex = parseInt(this.$route.query.active);
            }
            this.loadData();
        },
        methods:{
            loadData(){
                let that = this;
                this.post('/api/client/activity/talent/history').then(result => {
                    if (result.code === 0) {
                        this.list = result.data;
                    } else {
                        Dialog({message: result.msg});
                    }
                })
            },
            use(item){
                let that = this;
                if(item.overtime){
                    this.$dialog.confirm({
                        title: '提示',
                        message: '本次中奖机会已超时，请提前与店铺电话联系确认是否可用',
                        confirmButtonText:'继续',
                        cancelButtonText:'返回',
                    }).then(()=>{
                        that.$router.push({path: '/lottery/consume', query: {act_id: item.act_id}})
                    });
                } else {
                    that.$router.push({path: '/lottery/consume', query: {act_id: item.act_id}})
                }
            },
        }
    }
</script>

<style scoped>
    .container > div {
        margin: 10px;
        border: 1px solid #eee;
    }

    .list-cell .van-cell__title {
        flex: 0 0 75px;
    }

    .list-cell .van-cell__value {
        flex: 1 1 auto;
    }
</style>
<style>
    #lottery_mine .van-tabs__content {
        padding-top: 8px;
    }

    #lottery_mine .van-card__desc {
        font-size: 14px;
    }
</style>